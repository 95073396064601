<template>
  <div class="detail-relatives part">
    <div class="container">
      <div class="title">{{ title || "연관 프로젝트" }}</div>
      <div class="content">
        <ul class="tight" v-if="state.relatives.length">
          <li v-for="(r, idx) in state.relatives" :key="idx">
            <CombinedCard
                page="home"
                projectType="reward"
                :projectSeq="r.projectSeq"
                :link="`/reward/${r.projectSeq}`"
                :thumbFilePath="r.filePath + r.fileSaveName"
                :projectName="r.projectName"
                :progressOrder="r.progressOrder"
                :projectCate="r.projectCateName"
                :fundingType="r.fundingType"
                :count="r.investorCount"
                :percent="r.per"
                :dday="Number(r.dday)"
                :builderSeq="r.openId"
                :builderImageUrl="r.builderFilePath"
                :mainImagePosition="r.mainImagePosition"
                :thumbRatioToSquare="true"
                :noProfile="true"
            />
          </li>
        </ul>
        <div class="pt-50 pb-50" v-else-if="state.loaded">
          <NoData/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import Card from "@/components/Card.vue";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import CombinedCard from "@/components/CombinedCard.vue";

function Component(initialize) {
  this.name = "pageProjectDetailRelatives";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    title: String,
    projectSeq: Number,
    projectCate: String,
    simulationFlag: String,
  },
  components: {CombinedCard, NoData, Card},
  setup(props) {
    const component = new Component(() => {
      const args = {
        simulationFlag: props.simulationFlag || "N",
        projectCate: props.projectCate,
      };

      state.relatives = [];
      state.loaded = false;
      http.get(`/api/reward/projects/${props.projectSeq}/relation-projects`, args).then(({data}) => {
        state.loaded = true;

        for (let r of data.body) {
          r.filePath = r.filePath || "";
          r.fileSaveName = r.fileSaveName || "";
          state.relatives.push(r);
        }

        state.relatives.length > 4 && state.relatives.splice(4);
      });
    });

    const state = reactive({
      loaded: false,
      relatives: [],
    });

    return {component, state};
  }
});
</script>
<style lang="scss" scoped>
.detail-relatives {
  .title {
    font-weight: 600;
    font-size: $px20;
    margin-bottom: $px16;
  }

  .content {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: $px24 $px10;

      li {
        width: calc((100% - ($px10 * 4)) / 5);
      }
    }
  }

  @media(max-width: 767px) {
    .content {
      ul li {
        width: calc((100% - $px10) / 2);
      }

    }
  }
}
</style>